<template>
<div class="no-data" >
    <img :src="image" alt="暂无数据" />
    <span>{{desc}}</span>
</div>
</template>

<script>

export default {
    components: {},
    props: {
        desc: {
            type: String,
            default: '暂无相关数据'
        }
    },
    data() {
        return {
            image: require('@/assets/images/empty.png')
        }
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {}
}
</script>
<style lang="scss" scoped>
.no-data{
    width: 100%;
    padding: 40px 0 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {margin-top: 12px;}
    img{
        width: 89px;
        height: 56px;
    }
}
</style>